import { useForm, UseFormReturnType } from '@mantine/form';
import { useDebouncedState } from '@mantine/hooks';
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  DeviceType,
  useDevicesInfiniteQuery,
  useSpaceDetails,
} from '@portals/api/organizations';
import {
  DeviceStatusType,
  PaginatedFilterTypeEnum,
  PaginatedQueryParamsType,
} from '@portals/types';

import { useOverviewContext } from '../overview.context';
import { useOverviewRouteParams } from '../overview.hooks';

type DisplayType = 'list' | 'grid';

interface FormValues {
  statuses: DeviceStatusType[];
  sortByField: 'name' | 'status' | 'device_model_name';
  sortByDirection: 'asc' | 'desc';
}

interface OverviewDevicesContextType {
  form: UseFormReturnType<FormValues>;
  displayType: DisplayType;
  setDisplayType: (displayType: DisplayType) => void;
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;

  isFetchingNextPage: boolean;
  hasNextPage: ReturnType<typeof useDevicesInfiniteQuery>['hasNextPage'];
  fetchNextPage: ReturnType<typeof useDevicesInfiniteQuery>['fetchNextPage'];
  paginatedDevices: ReturnType<typeof useDevicesInfiniteQuery>['data'];
  allDevices: Array<DeviceType>;
  totalDevicesCount: number;
  isLoading: boolean;

  selectedDeviceIds: Set<string>;
  toggleSelectedDeviceId: (deviceId: string) => void;
  clearSelectedDeviceIds: VoidFunction;
}

const OverviewDevicesContext = createContext<OverviewDevicesContextType | null>(
  null
);

interface OverviewDevicesProviderProps {
  children: ReactNode;
}

export function OverviewDevicesProvider({
  children,
}: OverviewDevicesProviderProps) {
  const [displayType, setDisplayType] = useState<DisplayType>('list');

  const [searchTerm, setSearchTerm] = useDebouncedState('', 400);
  const [selectedDeviceIds, setSelectedDeviceIds] = useState(new Set<string>());

  const overview = useOverviewContext();
  const params = useOverviewRouteParams();
  const space = useSpaceDetails(Number(params.spaceId));

  const form = useForm<FormValues>({
    initialValues: {
      statuses: [],
      sortByDirection: 'asc',
      sortByField: 'name',
    },
  });

  function composeFilters() {
    if (!space.data) return;

    const filters: PaginatedQueryParamsType<DeviceType>['filters'] = [];

    if (form.values.statuses.length !== 0) {
      filters.push({
        id: 'status',
        value: form.values.statuses,
        type: PaginatedFilterTypeEnum.Select,
      });
    }

    if (searchTerm) {
      filters.push({
        id: 'name_or_device_model_name',
        value: searchTerm,
        type: PaginatedFilterTypeEnum.Text,
      });
    }

    if (overview.isLocalDataLevel) {
      filters.push({
        id: 'space_id',
        type: PaginatedFilterTypeEnum.Eq,
        value: space.data.id,
      });
    } else {
      filters.push({
        id: 'space_path',
        value: `"${encodeURIComponent(space.data.path.join('.'))}"`,
        type: PaginatedFilterTypeEnum.LtreeStartsWith,
      });
    }

    return filters;
  }

  function composeSorting() {
    return [
      {
        id: form.values.sortByField,
        desc: form.values.sortByDirection === 'desc',
      },
    ] satisfies PaginatedQueryParamsType<DeviceType>['sorting'];
  }

  function toggleSelectedDeviceId(deviceId: string) {
    setSelectedDeviceIds((prevSelectedDeviceIds) => {
      const newSelectedDeviceIds = new Set(prevSelectedDeviceIds);

      if (newSelectedDeviceIds.has(deviceId)) {
        newSelectedDeviceIds.delete(deviceId);
      } else {
        newSelectedDeviceIds.add(deviceId);
      }

      return newSelectedDeviceIds;
    });
  }

  function clearSelectedDeviceIds() {
    setSelectedDeviceIds(new Set());
  }

  useEffect(
    function resetSelectedDeviceIds() {
      // Whenever the user changes the filters/sort/search, we reset the selected device ids
      clearSelectedDeviceIds();
    },
    [form.values, searchTerm]
  );

  const {
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    data: paginatedDevices,
    isInitialLoading,
  } = useDevicesInfiniteQuery(
    {
      pagination: { page: 0, pageSize: 10 },
      sorting: composeSorting(),
      filters: composeFilters(),
    },
    { enabled: space.data !== undefined }
  );

  const allDevices = useMemo(() => {
    if (!paginatedDevices) return [];

    return paginatedDevices.pages.flatMap((devicesPage) => devicesPage.data);
  }, [paginatedDevices]);

  const totalDevicesCount =
    paginatedDevices?.pages?.[0]?.page_info?.total_count || 0;

  const isLoading = isInitialLoading || space.isInitialLoading;

  return (
    <OverviewDevicesContext.Provider
      value={{
        form,
        displayType,
        setDisplayType,
        searchTerm,
        setSearchTerm,

        paginatedDevices,
        allDevices,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isLoading,
        totalDevicesCount,

        selectedDeviceIds,
        toggleSelectedDeviceId,
        clearSelectedDeviceIds,
      }}
    >
      {children}
    </OverviewDevicesContext.Provider>
  );
}

export function useOverviewDevicesContext() {
  const context = useContext(OverviewDevicesContext);

  if (context === null) {
    throw new Error(
      'useOverviewDevicesContext must be used within OverviewDevicesProvider'
    );
  }

  return context;
}
