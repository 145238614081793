import { Text, TextProps } from '@mantine/core';
import React from 'react';

import {
  DeviceDetailsType,
  DeviceType,
  usePortalConfig,
} from '@portals/api/organizations';
import { DeviceType as CommonDeviceType } from '@portals/types';

interface DeviceCecPartnerLogoOrNameProps {
  device: DeviceType | DeviceDetailsType | CommonDeviceType;
  imgProps?: React.ImgHTMLAttributes<HTMLImageElement>;
  textProps?: TextProps;
}

export function DeviceCecPartnerLogoOrName({
  device,
  imgProps,
  textProps,
}: DeviceCecPartnerLogoOrNameProps) {
  const portalConfig = usePortalConfig({ staleTime: Infinity });

  if (!portalConfig.data?.cec_enabled) {
    return null;
  }

  if (device.partner.logo) {
    return (
      <img
        src={device.partner.logo}
        alt={device.partner.vendor}
        width={74}
        height="auto"
        {...imgProps}
      />
    );
  }

  return (
    <Text size="xl" {...textProps}>
      {device.partner.vendor}
    </Text>
  );
}
