import { DashboardLayout, DesignType, TenantType } from './common';

type NumericBoolean = 0 | 1;

/*========================= PARTNER =========================*/
export interface PartnerPortalCapabilities {
  support_center: boolean;
  device_monitoring: boolean;
  organization_paid_plans: boolean;
  organization_self_managed_warranties: boolean;
  organization_signup: boolean;
}

export type PartnerFeatureFlagsType = Partial<
  Record<
    PartnerFeatureFlagsKeys,
    CustomerFeatureFlagEnum | PartnerFeatureFlagEnum
  >
>;

export interface PartnerPricingPlanFeatures {
  audit_log_history_retention_in_months: number;
  device_models_limit: number;
  powered_by_xyte_label_toggle: NumericBoolean;
  registered_devices_limit: NumericBoolean;
  role_based_permissions: NumericBoolean;
  support_seats: number;
  custom_device_commands: NumericBoolean;
  product_sale_fee: NumericBoolean;
  custom_domain: NumericBoolean;
  api_access: NumericBoolean;
  store: NumericBoolean;
  branded_emails: NumericBoolean;
  store_branding: NumericBoolean;
}

export interface PartnerPricingPlanType {
  name: string;
  started_at: string;
  features: PartnerPricingPlanFeatures;
  icon_name: 'flag' | 'star' | 'crown' | 'flash';
  is_top_tier: boolean;
}

export interface ConfigPartnerType {
  id: string;
  name: string;
  display_name: string;
  logo_url: string | null;
  hardware_key: string;
  shortcode: string;
  device_count: number;
  onboarding_step: 'customUrl' | 'portalDesign' | 'complete';
  requested_tenant_deletion: boolean;
  billing_portal_url: string;
  classification: string;
  is_internal: boolean;
  is_cec_partner: boolean;
  completed_cec_migration: boolean;
}

export interface PartnerNotificationSettingsType {
  email: string;
  notify_referral?: boolean;
  notify_ticket?: boolean;
}

interface PartnerSettingsFeaturesType {
  ansa: boolean;
  demo: boolean;
  home: boolean;
  finance: boolean;
  partners: boolean;
  dashboard: boolean;
  warranties: boolean;
  credit_providers: boolean;
  support_dashboard: boolean;
  usage_based_pricing: boolean;
  enable_all_product_types: boolean;
  integrations?: Record<string, boolean>;
}

export interface ManufacturerManualType {
  created_at: string;
  id: string;
  file_name: string;
  manual_url: string;
  updated_at: string;
  partner_id: string;
  partner_display_name: string;
  partner_logo_url: string | null;
}

export interface PartnerConfigurationType {
  feature_flags: PartnerFeatureFlagsType;
  [TenantType.Partner]: ConfigPartnerType;
  portal_capabilities: PartnerPortalCapabilities;
  notification_settings: PartnerNotificationSettingsType;
  pricing_plan: PartnerPricingPlanType;
  settings: {
    features: PartnerSettingsFeaturesType;
  };
  visible_to_other_partners: boolean;
  manuals: ManufacturerManualType[];
}

export enum PartnerFeatureFlagEnum {
  On = 'on',
  Off = 'off',
}

export enum CustomerFeatureFlagEnum {
  Off = 'off',
  LabOnly = 'lab-only',
  All = 'all',
}

export enum FeatureFlagCategoryNamesEnum {
  Common = 'Common',
  Release = 'Release',
}

export enum FeatureFlagTypeEnum {
  Partner = 'partner',
  Customer = 'customer',
}

export interface PartnerFeatureFlagItemType {
  id: PartnerFeatureFlagsKeys;
  label: string;
  category: FeatureFlagCategoryNamesEnum;
  type: FeatureFlagTypeEnum;
  description: string;
}

export const FEATURE_LIST = [
  {
    id: 'home',
    label: 'Home',
    category: FeatureFlagCategoryNamesEnum.Common,
    type: FeatureFlagTypeEnum.Partner,
    description: 'Show home menu for partners',
  },
  {
    id: 'usage_based_pricing',
    label: 'Usage based pricing',
    category: FeatureFlagCategoryNamesEnum.Release,
    type: FeatureFlagTypeEnum.Partner,
    description: 'Ability to define usage based priced products',
  },
  {
    id: 'ppp',
    label: 'Post purchase params',
    category: FeatureFlagCategoryNamesEnum.Common,
    type: FeatureFlagTypeEnum.Partner,
    description: 'Ability for Seller to collect post-purchase parameters',
  },
  {
    id: 'personalized_pricing_model',
    label: 'Personalized pricing model',
    category: FeatureFlagCategoryNamesEnum.Common,
    type: FeatureFlagTypeEnum.Partner,
    description: 'Ability for seller to define products with a URL',
  },
  {
    id: 'custom_from_email',
    label: 'Custom From Email',
    category: FeatureFlagCategoryNamesEnum.Release,
    type: FeatureFlagTypeEnum.Partner,
    description:
      'Sets a custom "from" address on the emails sent to the end users',
  },
  {
    id: 'tunnel_poc',
    label: 'Tunnel POC',
    category: FeatureFlagCategoryNamesEnum.Release,
    type: FeatureFlagTypeEnum.Partner,
    description: 'Ability for OEM to set up tunneling for a device model',
  },
  {
    id: 'onboarding_video',
    label: 'Onboarding video',
    category: FeatureFlagCategoryNamesEnum.Release,
    type: FeatureFlagTypeEnum.Customer,
    description: 'Show a video tour of the end customer portal for new signups',
  },
  {
    id: 'onboarding_guides',
    label: 'Onboarding guides',
    category: FeatureFlagCategoryNamesEnum.Release,
    type: FeatureFlagTypeEnum.Customer,
    description: 'End customer setup and onboarding experience',
  },
  {
    id: 'ansa',
    label: 'Ansa Demo',
    category: FeatureFlagCategoryNamesEnum.Common,
    type: FeatureFlagTypeEnum.Partner,
    description: 'Flavor the financial dash demo for Ansa',
  },
  {
    id: 'volvo_demo',
    label: 'Volvo Demo',
    category: FeatureFlagCategoryNamesEnum.Common,
    type: FeatureFlagTypeEnum.Partner,
    description: 'Flavor the financial dash demo for Volvo',
  },
  {
    id: 'credit_providers',
    label: 'Credit Providers Demo',
    category: FeatureFlagCategoryNamesEnum.Common,
    type: FeatureFlagTypeEnum.Partner,
    description: 'Demo for loans view',
  },
  {
    id: 'enable_all_product_types',
    label: 'Enable all product types Demo',
    category: FeatureFlagCategoryNamesEnum.Common,
    type: FeatureFlagTypeEnum.Partner,
    description: 'Demo that adds greyed out product types',
  },
  {
    id: 'ticket_insight_demo',
    label: 'Ticket AI insight Demo',
    category: FeatureFlagCategoryNamesEnum.Common,
    type: FeatureFlagTypeEnum.Partner,
    description: 'Demo of AI based insights for tickets',
  },
  {
    id: 'organization_b2c_view',
    label: 'B2C View',
    category: FeatureFlagCategoryNamesEnum.Common,
    type: FeatureFlagTypeEnum.Customer,
    description: 'Configure the end customer experience to B2C',
  },
  {
    id: 'organization_service_providers',
    label: 'Service providers Demo',
    category: FeatureFlagCategoryNamesEnum.Common,
    type: FeatureFlagTypeEnum.Customer,
    description: 'Demo of Channel partner directory for the end customer',
  },
  {
    id: 'cec_pre_enrollment',
    label: 'Connect+ Pre Enrollment',
    category: FeatureFlagCategoryNamesEnum.Common,
    type: FeatureFlagTypeEnum.Partner,
    description:
      'Gives partners the ability to configure their branding before launching Connect+',
  },
  {
    id: 'analytics_dashboard',
    label: 'New overview',
    category: FeatureFlagCategoryNamesEnum.Release,
    type: FeatureFlagTypeEnum.Partner,
    description:
      'Enable access to new OEM analytics (replace "/dashboard" with "/analytics-dashboard")',
  },
] as const;

export type PartnerFeatureFlagsKeys = (typeof FEATURE_LIST)[number]['id'];

/*========================= ORGANIZATION =========================*/
export type OrganizationFeatureFlagKey =
  | 'crestron'
  | 'experts'
  | 'ansa'
  | 'managed_service_providers'
  | 'connect_plus'
  | 'paid_plans'
  | 'new_device_state_history'
  | 'orders_csv_export'
  | 'tunnel_demo'
  | 'hide_new_tickets_ui'
  | 'new_claiming_device_flow'
  | 'domotz'
  | 'neat'
  | 'assets'
  | 'overview_devices_tab';

export type OrganizationFeatureFlagsType = Partial<
  Record<OrganizationFeatureFlagKey, boolean>
>;

export interface OrganizationPricingPlanFeatures {
  audit_log_history_retention_in_months: number;
  role_based_permissions: NumericBoolean;
  insights: NumericBoolean;
  premium_integrations: NumericBoolean;
  incident_routing: NumericBoolean;
}

export interface OrganizationPortalCapabilities {
  store: boolean;
  support_center: boolean;
  device_monitoring: boolean;
  paid_plans: boolean;
  uc_cloud: boolean;
  uc_3rd_party: boolean;
  purchased_products: boolean;
  self_managed_warranties: boolean;
  b2c_view: CustomerFeatureFlagEnum;
  service_providers: CustomerFeatureFlagEnum;
  onboarding_video: CustomerFeatureFlagEnum;
  onboarding_guides: CustomerFeatureFlagEnum;
}

export interface OrganizationConfigType {
  id: string;
  name: string;
  display_name: string;
  partner_display_name: string;
  partner_name: string;
  partner_support_email: string | null;
  admin_user_email: string | null;
  lab: boolean;
  tagline: string | null;
  requested_tenant_deletion: boolean;
  design: DesignType;
  free_trial_status: 'available' | 'in_progress' | 'expired';
  remaining_trial_days?: number; // will only appear if free trial status is 'in_progress'
  logo_url: string | null;
  is_internal: boolean;
  is_sandbox: boolean;
  partner_manuals: ManufacturerManualType[];
}

export interface OrganizationNotificationSettingsType {
  email?: string;
}

export interface OrganizationReportSettingsType {
  enabled: boolean;
  email: string;
  utc_time: string;
  only_new: boolean;
}

interface OnboardingToursType {
  dismiss: boolean;
  isWelcomeVideoPlayed: boolean;
  getting_started: {
    setup_spaces_completed: boolean;
    claim_device_completed: boolean;
    monitoring_completed: boolean;
  };
}

export interface OrganizationPricingPlanType {
  name: string;
  started_at: string;
  features: OrganizationPricingPlanFeatures;
  icon_name: 'flag' | 'crown';
  is_top_tier: boolean;
}

export interface OrganizationConfigurationType {
  [TenantType.Organization]: OrganizationConfigType;
  has_connectors: boolean;
  feature_flags: OrganizationFeatureFlagsType;
  portal_capabilities: OrganizationPortalCapabilities;
  pricing_plan: OrganizationPricingPlanType;
  space_dashboard?: DashboardLayout;
  settings: {
    notifications?: OrganizationNotificationSettingsType;
  };
  report_settings: OrganizationReportSettingsType;
  onboarding_tours: OnboardingToursType;
}

/*========================= COMMONS =========================*/
export type CommonFeatureFlagsType =
  | OrganizationFeatureFlagsType
  | PartnerFeatureFlagsType;

export type CommonPortalCapabilities =
  | OrganizationPortalCapabilities
  | PartnerPortalCapabilities;

export type CommonConfigurationType =
  | PartnerConfigurationType
  | OrganizationConfigurationType;
