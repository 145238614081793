import {
  Checkbox,
  createStyles,
  Group,
  LoadingOverlay,
  Stack,
  Text,
} from '@mantine/core';
import React from 'react';

import { DeviceType } from '@portals/api/organizations';
import {
  DeviceAvatar,
  DeviceCecPartnerLogoOrName,
  DeviceStatusBadge,
} from '@portals/framework';
import { useOpenRouteModal } from '@portals/framework/route-modals';
import { timeFromNow } from '@portals/utils';

import { useDeviceDrag } from '../../../../../hooks/device-cards';
import { DeviceIncidentsCounterWithIcon } from '../../../../components/DeviceCounters';
import { DeviceRelationsIndicator } from '../../device-indicators/DeviceRelationsIndicator';
import { DeviceSnoozeIndicator } from '../../device-indicators/DeviceSnoozeIndicator';
import { DeviceMenu } from '../../device-menu/DeviceMenu';
import { useOverviewDevicesContext } from '../OverviewDevicesProvider';

interface DeviceTileProps {
  device: DeviceType;
}

export function DeviceTile({ device }: DeviceTileProps) {
  const { classes } = useStyles();

  const openRouteModal = useOpenRouteModal();

  const { dragRef, isLoading } = useDeviceDrag(device);

  const { selectedDeviceIds, toggleSelectedDeviceId } =
    useOverviewDevicesContext();

  return (
    <div className={classes.container} ref={dragRef}>
      <LoadingOverlay
        visible={isLoading}
        loaderProps={{ size: 'sm' }}
        radius="md"
      />

      <Checkbox
        checked={selectedDeviceIds.has(device.id)}
        onChange={() => toggleSelectedDeviceId(device.id)}
      />

      <div
        className={classes.middleContent}
        onClick={() =>
          openRouteModal({ modalId: 'device', pathParams: [device.id] })
        }
      >
        <Group spacing="xl" noWrap className={classes.overflow}>
          <Group noWrap>
            <DeviceCecPartnerLogoOrName
              device={device}
              imgProps={{ loading: 'lazy', width: 90 }}
              textProps={{ size: 'sm', w: 90, truncate: true }}
            />

            <DeviceAvatar
              src={device.image_url}
              icon={device.model_settings?.icon}
              size={48}
              radius="md"
            />
          </Group>

          <Stack spacing={4} className={classes.overflow}>
            <Text truncate>{device.name}</Text>

            <Text fz="xs" c="gray.5" truncate>
              {device.partner.model}
            </Text>
          </Stack>
        </Group>

        <Group noWrap>
          <DeviceSnoozeIndicator device={device} />

          <DeviceRelationsIndicator device={device} />

          <DeviceIncidentsCounterWithIcon device={device} />

          <Text className={classes.lastSeen}>
            {timeFromNow(device.last_seen)}
          </Text>

          <Group position="right" w={105}>
            <DeviceStatusBadge status={device.status} dark />
          </Group>

          <DeviceMenu device={device} />
        </Group>
      </div>
    </div>
  );
}

const useStyles = createStyles((theme) => ({
  container: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing.md,
    paddingBlock: theme.spacing.xs,
    paddingInline: theme.spacing.xl,
    borderRadius: theme.radius.md,
    borderBottom: `1px solid ${theme.colors.gray[2]}`,
    backgroundColor: theme.white,
    cursor: 'pointer',

    ...theme.fn.hover({
      boxShadow: theme.shadows.xs,
    }),
  },
  middleContent: {
    overflow: 'hidden',
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing.xl,
  },
  lastSeen: {
    fontSize: theme.fontSizes.xs,
    color: theme.colors.gray[5],
    whiteSpace: 'nowrap',
  },
  overflow: {
    overflow: 'hidden',
  },
}));
